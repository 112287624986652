@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin disableTextSelection {
  user-select: none; /* Standard syntax */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -webkit-user-select: none; /* Safari */
}

@mixin headLine4 {
  font-weight: 450;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.7px;
}

@mixin headLine5 {
  font-weight: 450;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.32px;
}

@mixin headLine6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
}
