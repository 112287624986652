@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  @include disableTextSelection;
  cursor: pointer;
  position: relative;

  .menuArrow {
    width: 14px;
    fill: $appGray;
    transform: rotate(180deg);
  }

  &:hover {
    .menuArrow {
      svg {
        fill: $appBlue;
      }
    }
  }
}

.userName {
  font-weight: 500;
  font-size: $fontSizeBodyS;
  line-height: 20px;
  text-align: right;
  word-wrap: break-word;
}

.avatar {
  @include centeredFlex;
  width: 45px;
  height: 45px;
}

.popover {
  position: absolute;
  right: 0;
  top: 50px;
  min-width: 160px;
  padding: 5px 0;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  z-index: 1000;

  ul {
    overflow: hidden;
    li {
      text-align: left;
      white-space: nowrap;
      margin: 0 5px;
      cursor: pointer;
      color: $appBlack;
      border-radius: 4px;

      &:hover {
        background: #f8f8f8;
        color: $appBlue;
        a,
        button {
          color: $appBlue;
        }
      }

      a,
      button {
        color: $appBlack;
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 14px;
      }
      button {
        text-align: left;
      }
    }
  }
}
